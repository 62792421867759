(function ($) {
  $(window).load(function () {
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      let headerOuterHeight = 0;
      $headerHeight.children().each(function () {
        headerOuterHeight += $(this).outerHeight();
      });
      console.error(headerOuterHeight);

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop = $message.offset().top - headerOuterHeight - 175;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: newScrollTop,
      });
    }, 500);

    $(".owl-carousel:not(.slider)").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],

      dots: true,

      // drag options
      mouseDrag: false,
    });

    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: [
        "<i class='fas fa-chevron-left'></i>",
        "<i class='fas fa-chevron-right'></i>",
      ],

      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });
  });

  $(document).ready(function () {
    require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 500);
      }
    });
  });
})(jQuery);

// colored titles
let titles = document.querySelectorAll(".title *");
titles.forEach((title) => {
  let newHTML = "";
  let arr = title.innerHTML.split(" ");
  arr.forEach((word) => {
    if (word.startsWith("{{") && word.endsWith("}}")) {
      newHTML += `<span class='highlight'>${word
        .replaceAll("{", "")
        .replaceAll("}", "")
        .replaceAll("_", " ")}</span> `;
    } else {
      newHTML += word + " ";
    }
  });
  title.innerHTML = newHTML.trim();
});
